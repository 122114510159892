<template>
  <div class="Operation_Log">
    <a-steps direction="vertical" size="small" :current="listArr.length + 2">
      <a-step
        v-for="(item, k) in listArr"
        :key="k"
        :title="item.time"
        :description="item.title"
      >
        <div slot="icon" class="border ">{{ item.title.slice(0, 1) }}</div>
      </a-step>
    </a-steps>
    <div style="float: right;">
      <a-pagination
        showQuickJumper
        :defaultCurrent="current"
        size="small"
        :total="total"
        @change="onChange"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'Operation_Log',
  data () {
    return {
      listArr: [
        {
          time: '2019-12-28 12:30:45',
          title:
            '张佩佩添加郑蒙婷的新员工培训记录。天天迟到。新员工培训学习测试55分，表现非常差'
        },
        {
          time: '2019-11-27 12:30:45',
          title:
            '陈秀清添加郑蒙婷的新员工培训记录。培训期间将悄悄话，表现活跃。新员工培训学习测试75分，表现非常优秀'
        },
        {
          time: '2019-10-26 12:30:45',
          title:
            '吴丽妹添加郑蒙婷的新员工培训记录。培训期间不发言。新员工培训学习测试65分，表现非常ok'
        },
        {
          time: '2019-09-25 12:30:45',
          title:
            '朱田云添加郑蒙婷的新员工培训记录。培训期间积极发言，表现活跃。新员工培训学习测试85分，表现非常优秀'
        }
      ],
      total: 500,
      current: 1
    }
  },
  methods: {
    onChange (pageNumber) {
      console.log('Page: ', pageNumber)
    }
  }
}
</script>
<style lang="less" scoped>
.Operation_Log {
  .border {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    font-size: 14px;
    line-height: 22px;
  }
}
</style>
